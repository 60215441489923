export const projects = [
  {
    title: "Graphite on paper",
    subtitle: "Portrait",
    description:
    "                              ",
    //description:
    //  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./portrait.jpg",
    //link: "https://reactbootcamp.com",
  },
  {
    title: "Graphite on paper",
    subtitle: "Portrait",
    //description:
    //  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./portrait2.jpg",
    //link: "https://reactbootcamp.com",
  },
  {
    title: "Graphite on paper",
    subtitle: "Portrait",
    //description:
    //  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./helen.jpg",
    //link: "https://reactbootcamp.com",
  },
  {
    title: "Procreate",
    subtitle: "Portrait",
    //description:
    //  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./portraitdigital.jpg",
    //link: "https://reactbootcamp.com",
  },
  {
    title: "Multimedia: oil, graphite, and pastel on paper",
    subtitle: "Portrait",
    //description:
    //  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./ear2.jpg",
    //link: "https://reactbootcamp.com",
  },
  {
    title: "Coloured pencils on paper",
    subtitle: "Experimentation",
    //description:
    //  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./eye.jpg",
    //link: "https://reactbootcamp.com",
  },
  {
    title: "Charcoal on paper",
    subtitle: "Portrait",
    //description:
    //  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./north.jpg",
    //link: "https://reactbootcamp.com",
  },
  {
    title: "Sketchbook Page",
    subtitle: "Pen on paper",
    description:
      "I often draw while watching shows",
    image: "./netflix3.jpg",
    //link: "https://reactbootcamp.com",
  },
  {
    title: "Charcoal on paper",
    subtitle: "Portrait",
    //description:
    //  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./baby.jpg",
    //link: "https://reactbootcamp.com",
  },
];

export const testimonials = [
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Jesse Hicks",
    company: "Zoozle",
  },
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
    image: "https://randomuser.me/api/portraits/men/94.jpg",
    name: "Ruben Alvarez",
    company: "Dooble.io",
  },
];

export const skills = [
  "JavaScript",
  "React",
  "Next.js",
  "Node",
  "GraphQL",
  "Material UI",
];
