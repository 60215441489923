import { CodeIcon } from "@heroicons/react/solid";
import React from "react";
import { projects } from "../data";

export default function Projects() {
  return (
    <section id="projects" className="text-gray-400 bg-black body-font">
      <div className="container px-4 py-4 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-20">
          <CodeIcon className="mx-auto inline-block w-10 mb-10" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
            My Works
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base"></p>
        </div>
        <div className="flex flex-wrap -m-4">
          {projects.map((project) => (
            <div
              key={project.image}
              className="lg:w-1/2 w-full p-4 group"
              style={{ minHeight: "300px" }}
            >
              <div className="relative overflow-hidden">
                <img
                  alt="gallery"
                  className="w-full h-full object-cover max-w-md"
                  src={project.image}
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-90 transition-opacity duration-300 bg-gray-900">
                  <div className="px-4 py-4 text-center">
                    <h2 className="tracking-widest text-sm title-font font-medium text-green-600 mb-1">
                      {project.subtitle}
                    </h2>
                    <h1 className="title-font text-lg font-medium text-white mb-3">
                      {project.title}
                    </h1>
                    <p className="leading-relaxed text-gray-400">
                      {project.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}