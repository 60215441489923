import React from "react";

export default function About() {
  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            Hi there, I'm Sherry.
            <br className="hidden lg:inline-block" /> I like to draw faces. 
          </h1>
          <p className="mb-3 leading-relaxed">
            It began with at the age of 9 with a 2b graphite pencil, sheet of printer paper, 
            and reference photo of Einstein. 
         
          </p>
          <p className="mb-3 leading-relaxed">
             
            A successful portrait has an immeasurable effect. The task goes beyond recreation;
            a portrait can capture one's life story in a single frame. The complex interplay between the subject and the artistic
            choices creates a unique visual experience that a photograph cannot mimic.    
            When the reference photograph pales in comparison to the portrait, I know the piece is complete. 
          
          </p>
          <p className="mb-3 leading-relaxed">
          Portrait drawing has been my focus for the past few years.  
          However, I am always open to trying new mediums and requests outside of portraits. 
          I am currently working on digitally illustrating a children's book for a self-published author.  
          </p>
          <div className="flex justify-center">
            <a
              href="#skills"
              className="inline-flex text-white bg-gray-500 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 rounded text-lg">
              Commissions 
            </a>
            <a
              href="#projects"
              className="ml-4 inline-flex text-white bg-blue-600 border-0 py-2 px-6 focus:outline-none hover:bg-blue-800 hover:text-white rounded text-lg">
              See My Work
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src="./girlpainting.svg"
          />
        </div>
      </div>
    </section>
  );
}
