import { ChipIcon } from "@heroicons/react/solid";
import React from "react";

export default function Skills() {
  return (
    <section id="skills">
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-20">
          <ChipIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
            Commissions & Inquiries
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            I am open to commissions! Submit an inquiry through the contact form below.
            I prefer to work from live models and can travel to subjects southern Ontario. 
            If live modeling is not an option, I require high quality reference photos subject to approval. 
            International shipping is available.  
          </p>
          <div className="w-20 inline-block mb-4" />
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Pricing is based on the size, medium, and subject(s). 
            Please include the following information in your inquiry: medium, subject(s), size, timeframe, and special requests. 
            Note that a portrait requires 2 weeks minimum. 
          </p>
        </div>
      </div>
    </section>
  );
}